<div class="min-vh-100 d-flex flex-column justify-content-center">
  <div class="container">
    <div class="white text-center my-5 pointer" routerLink="/">
      <app-eemcs></app-eemcs>
      <h1>EEMCS Teacher of the Year</h1>
    </div>

    <div class="d-flex">
      <div
        class="col-4 tab"
        [routerLink]="['teachers']"
        routerLinkActive="active"
      >
        Teachers
      </div>
      <div
        class="col-4 tab"
        [routerLink]="['courses']"
        routerLinkActive="active"
      >
        Courses
      </div>
      <div
        class="col-4 tab"
        [routerLink]="['results']"
        routerLinkActive="active"
      >
        Results
      </div>
    </div>
  </div>

  <div class="main">
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
