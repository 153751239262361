<svg
  xmlns="http://www.w3.org/2000/svg"
  width="300"
  height="90"
  viewBox="100 28 20 58"
  version="1.1"
  class="eemcs"
>
  <g>
    <path
      d="M 40.065475,129.69412 V 53.583331 l 15.686014,-6.916963 v 82.848252"
    />
    <path
      d="m 83.721725,129.69829 0,-75.094422 12.473215,-4.346726 0,79.370268"
    />
    <path
      d="m 40.065477,36.196427 v 6.425597 L 55.37351,35.818452 83.343749,44.09613 96.19494,39.447023 184.0744,67.946428 V 62.465772 L 96.19494,33.172619 83.343749,38.275297 55.184523,29.203869 Z"
    />
    <path d="M 184.1487,78.095512 V 129.67854" />
  </g>
</svg>
