import { Component } from '@angular/core';
import { EditionService } from '@app/edition.service';
import { Edition, User, Vote } from '../all.model';
import { UserService } from '../user.service';
import { VoteService } from '../vote.service';

@Component({
  templateUrl: './done.component.html',
})
export class DoneComponent {
  get user(): User {
    return this._user.user;
  }

  get edition(): Edition {
    return this._edition.edition;
  }

  get active(): boolean {
    return this._edition.active;
  }

  constructor(
    private _user: UserService,
    private _vote: VoteService,
    private _edition: EditionService,
  ) {}

  votes(no: number): Vote {
    return this._vote.vote(no);
  }
}
