<div class="all">
  <div class="text-center white">
    <app-eemcs></app-eemcs>
    <h1>EEMCS Teacher of the Year</h1>
    <h2>Vote for your favorite teacher</h2>
    <p>
      <i
        >{{ edition.start_date | date: 'EEEE, MMMM d' }} -
        {{ edition.end_date | date: 'EEEE, MMMM d' }}</i
      >
    </p>

    <a href="api/saml/login" class="btn btn-outline-primary login-button my-4">
      <h4>Login</h4>
      <p class="mb-0">with your netid</p>
    </a>

    <p>
      <i>
        We will use your netid to make sure everyone can vote only once. Your
        data will be kept anonymously.
      </i>
    </p>
  </div>
</div>
