<div class="row">
  <div class="col-md-4">
    <div class="d-flex justify-content-between mb-3">
      <div>
        <button
          *ngIf="before"
          type="button"
          class="btn btn-outline-secondary mr-3"
          (click)="import()"
        >
          Import
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="settings()"
        >
          Settings
        </button>
      </div>

      <button type="button" class="btn btn-outline-primary" (click)="edit()">
        Create
      </button>
    </div>

    <input
      class="form-control mb-3"
      placeholder="Search teacher"
      [ngModel]="search"
      (ngModelChange)="update.next($event)"
    />
  </div>

  <div class="col-md-8">
    <div
      class="teacher d-flex justify-content-between align-items-center"
      *ngFor="let teacher of teachers"
    >
      <div class="name">{{ teacher.name }}</div>

      <div class="d-flex justify-content-end align-items-center">
        <i class="text-nowrap">{{ teacher.courses?.length }} courses</i>
        <button
          type="button"
          class="btn btn-outline-info"
          (click)="edit(teacher)"
        >
          Edit
        </button>
      </div>
    </div>
    <p class="text-center" *ngIf="!teachers?.length">
      <i>There are no teachers yet...</i>
    </p>
  </div>
</div>
