import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { EditionService } from './edition.service';
import { UserService } from './user.service';

@Injectable()
export class StageGuard implements CanActivate {
  constructor(
    private edition: EditionService,
    private user: UserService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const path = route.routeConfig.path;

    await this.edition.resolve();
    const user = await this.user.resolve();

    if (
      (path === 'info' && !this.edition.before) ||
      (path === 'vote' && !this.edition.active && !user.admin) ||
      (path === 'done' && this.edition.before && !user.admin)
    ) {
      switch (true) {
        case this.edition.before:
          return this.router.parseUrl('/info');
        case this.edition.active:
          return this.router.parseUrl('/vote');
        case this.edition.after:
          return this.router.parseUrl('/done');
      }
    }

    return true;
  }
}
