import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Course, Teacher } from '../all.model';

@Component({
  templateUrl: './teacher-modal.component.html',
})
export class TeacherModalComponent implements OnInit {
  form: FormGroup;

  teacher: Teacher;

  courses: Course[];

  constructor(
    private http: HttpClient,
    private modal: NgbActiveModal,
    private formBuilder: FormBuilder,
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      id: [],
      name: ['', [Validators.required]],
      mail: ['', [Validators.email, Validators.required]],
      courses: [[], [Validators.required]],
    });

    this.form.patchValue(this.teacher);
  }

  async save(): Promise<void> {
    try {
      const teacher = await this.http
        .post('/api/admin/teacher', this.form.value)
        .toPromise();

      this.modal.close(teacher);
    } catch (e) {
      console.error(e);
    }
  }

  async delete(): Promise<void> {
    try {
      await this.http
        .delete(`/api/admin/teacher/${this.teacher.id}`)
        .toPromise();

      this.modal.close();
    } catch (e) {
      console.error(e);
    }
  }

  close(): void {
    this.modal.dismiss();
  }
}
