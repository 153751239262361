import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Study, Teacher } from '../all.model';

@Component({
  templateUrl: './results.component.html',
})
export class ResultsComponent implements OnInit {
  bachelors = [
    { name: 'EE', selected: true },
    { name: 'AM', selected: true },
    { name: 'CS', selected: true },
  ];
  masters: { name: string; selected: boolean }[];

  selected = [];

  results: any;

  teacher: any;

  constructor(private http: HttpClient, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.http.get<Study[]>('/api/admin/studies').subscribe(masters => {
      this.masters = masters
        .filter(m => !m.year)
        .map(m => ({ name: m.name, selected: true }));

      this.fetch();
    });
  }

  fetch(): void {
    this.selected = [
      ...this.bachelors.filter(b => b.selected).map(b => 'BSc ' + b.name),
      ...this.masters.filter(b => b.selected).map(b => 'MSc ' + b.name),
    ];

    this.http
      .get<any>('/api/admin/results', {
        params: { studies: this.selected.join(',') },
      })
      .subscribe(results => (this.results = results));
  }

  avg(part: number, whole: number, percentage: boolean = false): string {
    if (percentage) {
      return whole ? ((part / whole) * 100).toFixed(0) : '-';
    } else {
      return whole ? (part / whole).toFixed(2) : '-';
    }
  }

  details(teacher: Teacher, template: TemplateRef<any>): void {
    this.http.get(`/api/admin/result/${teacher.id}`).subscribe(teacher => {
      this.teacher = teacher;

      this.modalService.open(template, {
        size: 'xl',
        animation: false,
        centered: true,
      });
    });
  }

  download(): void {
    this.http
      .get('/api/admin/results/download', {
        params: { studies: this.selected.join(',') },
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .subscribe((response: HttpResponse<Blob>) => {
        const name = response.headers.get('content-disposition').slice(22, -1);
        const linkElement = document.createElement('a');
        const url = window.URL.createObjectURL(response.body);

        linkElement.setAttribute('href', url);
        linkElement.setAttribute('download', name);

        const clickEvent = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        linkElement.dispatchEvent(clickEvent);
      });
  }
}
