import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Study } from '@app/all.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './import-modal.component.html',
})
export class ImportModalComponent implements OnInit {
  file: File;

  dragging = false;

  error = null;

  studies: Study[];

  constructor(private http: HttpClient, private modal: NgbActiveModal) {}

  async ngOnInit(): Promise<void> {
    this.studies = await this.http
      .get<Study[]>('/api/admin/studies')
      .toPromise();
    this.studies.forEach(s => {
      s.name = [s.year ? 'BSc' : 'MSc', s.name, s.year]
        .filter(x => x)
        .join(' ');
    });
  }

  setFile(files: FileList): void {
    this.file = files.item(0);
  }

  async import(): Promise<void> {
    this.error = null;

    const formData = new FormData();
    formData.append('file', this.file);

    try {
      await this.http.post<number>('/api/admin/import', formData).toPromise();

      this.modal.close();
    } catch (e) {
      this.error = JSON.parse(e.error.message);

      console.error(e);
    }
  }

  async template(): Promise<void> {
    this.http
      .get('/api/admin/import', {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .subscribe((response: HttpResponse<Blob>) => {
        const name = response.headers.get('content-disposition').slice(22, -1);
        const linkElement = document.createElement('a');
        const url = window.URL.createObjectURL(response.body);

        linkElement.setAttribute('href', url);
        linkElement.setAttribute('download', name);

        const clickEvent = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        linkElement.dispatchEvent(clickEvent);
      });
  }

  close(): void {
    this.modal.dismiss();
  }
}
