import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Teacher, Vote, Votes } from './all.model';

@Injectable()
export class VoteService {
  private _votes: Votes = {
    1: null,
    2: null,
    3: null,
  };

  no = 1;
  selecting = true;

  constructor(private http: HttpClient) {
    this.http.get('/api/vote').subscribe((votes: Votes) => {
      this._votes = votes;

      for (this.no = 1; this.no <= 3; this.no++) {
        if (votes[this.no] && !votes[this.no].comments) {
          this.selecting = false;
          break;
        } else if (!votes[this.no]) {
          break;
        }
      }
    });
  }

  vote(no: number = this.no): Vote {
    return (
      this._votes[no] || {
        teacher: null,
        comments: null,
      }
    );
  }

  teacher(no: number): string {
    const teacher = this.vote(no).teacher;

    if (!teacher) {
      return;
    }

    return teacher.name.substring(teacher.name.lastIndexOf('.') + 2);
  }

  saveVote(
    teacher: Teacher = this.vote().teacher,
    comments: string = this.vote().comments,
  ): void {
    this._votes[this.no] = { teacher, comments };

    this.http
      .post('/api/vote', {
        teacher_id: teacher.id,
        number: this.no,
        comments,
      })
      .subscribe(() => {});
  }

  get selected(): number[] {
    return [2, 3]
      .filter(x => x !== this.no)
      .map<Vote>(x => this._votes[x])
      .filter(Boolean)
      .map(x => x.teacher.id);
  }
}
