import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CourseModalComponent } from '@app/course-modal/course-modal.component';
import { EditionModalComponent } from '@app/edition-modal/edition-modal.component';
import { EditionService } from '@app/edition.service';
import { ImportModalComponent } from '@app/import-modal/import-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import FuzzySearch from 'fuzzy-search';
import { Subject } from 'rxjs';
import { Course, Study, Teacher } from '../all.model';

@Component({
  templateUrl: './courses.component.html',
})
export class CoursesComponent implements OnInit {
  private _courses: Course[];
  courses: Course[];

  search = '';
  private fuzzy: FuzzySearch<Course>;
  update: Subject<string>;

  teachers: Teacher[];
  studies: Study[];

  get before(): boolean {
    return this.edition.before;
  }

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private edition: EditionService,
  ) {}

  async ngOnInit(): Promise<void> {
    const courses = await this.http
      .get<Course[]>('/api/admin/courses')
      .toPromise();

    this.teachers = await this.http
      .get<Teacher[]>('/api/admin/teachers')
      .toPromise();
    this.studies = await this.http
      .get<Study[]>('/api/admin/studies')
      .toPromise();
    this.studies.forEach(s => {
      s.name = [s.year ? 'BSc' : 'MSc', s.name, s.year]
        .filter(x => x)
        .join(' ');
    });

    this.update = new Subject<string>();
    this._courses = courses;
    this.courses = courses;

    this.fuzzy = new FuzzySearch(courses, ['name', 'code']);
    this.update.next();

    this.update.subscribe((search: string) => {
      this.fuzzy.haystack = this._courses;
      this.courses = this.fuzzy.search(search);
    });
  }

  async edit(course?: Course): Promise<void> {
    const modal = this.modalService.open(CourseModalComponent);
    const component: CourseModalComponent = modal.componentInstance;

    component.course = course;
    component.teachers = this.teachers;
    component.studies = this.studies;

    try {
      const t = await modal.result;
      if (t) {
        if (course) {
          Object.assign(course, t);
        } else {
          this._courses.push(t);
        }
      } else {
        this._courses = this._courses.filter(t => t.id !== course.id);
      }
    } catch {}

    this.search = '';
    this.update.next('');
  }

  async import(): Promise<void> {
    const modal = this.modalService.open(ImportModalComponent);

    try {
      await modal.result;

      this.ngOnInit();
    } catch {}
  }

  settings(): void {
    this.modalService.open(EditionModalComponent);
  }
}
