<div class="all">
  <div class="text-center white">
    <h1>Sorry, {{ name }}!</h1>

    <p>
      We are sorry to say you can not vote here.
      {{
        noeemcs
          ? 'There probably is an equivalent vote on your faculty.'
          : 'Only students can vote for the contest.'
      }}
    </p>
  </div>
</div>
