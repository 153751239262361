import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { EditionModalComponent } from '@app/edition-modal/edition-modal.component';
import { EditionService } from '@app/edition.service';
import { ImportModalComponent } from '@app/import-modal/import-modal.component';
import { TeacherModalComponent } from '@app/teacher-modal/teacher-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import FuzzySearch from 'fuzzy-search';
import { Subject } from 'rxjs';
import { Course, Teacher } from '../all.model';

@Component({
  templateUrl: './teachers.component.html',
})
export class TeachersComponent implements OnInit {
  private _teachers: Teacher[];
  teachers: Teacher[];

  search = '';
  private fuzzy: FuzzySearch<Teacher>;
  update: Subject<string>;

  courses: Course[];

  get before(): boolean {
    return this.edition.before;
  }

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private edition: EditionService,
  ) {}

  async ngOnInit(): Promise<void> {
    const teachers = await this.http
      .get<Teacher[]>('/api/admin/teachers')
      .toPromise();

    this.courses = await this.http
      .get<Course[]>('/api/admin/courses')
      .toPromise();

    this.update = new Subject<string>();
    this._teachers = teachers;
    this.teachers = teachers;

    this.fuzzy = new FuzzySearch(teachers, ['name']);
    this.update.next();

    this.update.subscribe((search: string) => {
      this.fuzzy.haystack = this._teachers;
      this.teachers = this.fuzzy.search(search);
    });
  }

  async edit(teacher?: Teacher): Promise<void> {
    const modal = this.modalService.open(TeacherModalComponent);
    const component: TeacherModalComponent = modal.componentInstance;

    component.courses = this.courses;
    component.teacher = teacher;

    try {
      const t = await modal.result;
      if (t) {
        if (teacher) {
          Object.assign(teacher, t);
        } else {
          this._teachers.push(t);
        }
      } else {
        this._teachers = this._teachers.filter(t => t.id !== teacher.id);
      }
    } catch {}

    this.search = '';
    this.update.next('');
  }

  async import(): Promise<void> {
    const modal = this.modalService.open(ImportModalComponent);

    try {
      await modal.result;

      this.ngOnInit();
    } catch {}
  }

  settings(): void {
    this.modalService.open(EditionModalComponent);
  }
}
