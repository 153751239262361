<div class="all">
  <div class="container text-center white">
    <h1>
      Thanks, {{ user.name }}!
      <a *ngIf="user.admin" routerLink="/admin">*</a>
    </h1>

    <p>
      If you would like to change your vote, you can do so until
      {{ edition.end_date | date: 'EEEE, MMMM d' }}. You will be able to check
      out the results at the award show on
      {{ edition.award_date | date: 'EEEE, MMMM d' }}!
    </p>

    <div class="row">
      <div
        class="col-md-4"
        *ngFor="let no of [1, 2, 3]"
      >
        <h1 class="mt-4">{{ no === 1 ? 'Online' : no - 1 }}</h1>
        <h3>{{ votes(no).teacher ? votes(no).teacher?.name : '-' }}</h3>
        <p>
          <i>{{ votes(no).comments ? '"' + votes(no).comments + '"' : '' }}</i>
        </p>
      </div>
    </div>

    <div class="mt-3">
      <button
        class="btn btn-outline-secondary text-white mr-3"
        *ngIf="active"
        routerLink="/"
      >
        Change vote
      </button>
      <a class="btn btn-outline-primary text-white" href="api/saml/logout">
        Logout
      </a>
    </div>
  </div>
</div>
