import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditionService } from '@app/edition.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Edition } from '../all.model';

@Component({
  templateUrl: './edition-modal.component.html',
})
export class EditionModalComponent implements OnInit {
  form: FormGroup;

  constructor(
    private http: HttpClient,
    private modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private editionService: EditionService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      start_date: [null, [Validators.required]],
      end_date: [null, [Validators.required]],
      award_date: [null, [Validators.required]],
      admins: [[], [Validators.required]],
      extra: [[]],
    });

    try {
      this.form.patchValue(
        await this.http.get<Edition>('/api/admin/edition').toPromise(),
      );
    } catch (e) {
      console.error(e);
    }
  }

  addNetid(netid: string) {
    return netid;
  }

  async save(): Promise<void> {
    try {
      await this.http.post('/api/admin/edition', this.form.value).toPromise();

      this.editionService.resolve();

      this.modal.close();
    } catch (e) {
      console.error(e);
    }
  }

  close(): void {
    this.modal.dismiss();
  }
}
