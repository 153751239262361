<form [formGroup]="form" (submit)="save()">
  <div class="d-flex">
    <div class="flex-grow-1 modal-header">
      <h4 class="modal-title w-100 text-center">
        Settings
      </h4>
    </div>
    <div
      class="modal-header pointer ml-2 d-flex justify-content-center"
      [style.width.px]="68"
      (click)="close()"
    >
      <h4 class="modal-title size-lg">
        &times;
      </h4>
    </div>
  </div>
  <div class="modal-body">
    Here you can set the start and end date of the voting procedure, as well as
    the date of the award mentioned throughout the application. The list of
    admin netids determines who can fill the database and check the results. The
    list of extra netids is for those whose netid for some reason does not allow
    them to login.
  </div>
  <table class="table">
    <tbody>
      <tr>
        <td><label for="start_date">Start</label></td>
        <td>
          <input
            type="date"
            formControlName="start_date"
            name="start_date"
            id="start_date"
            class="form-control"
          />
        </td>
      </tr>
      <tr>
        <td><label for="end_date">End</label></td>
        <td>
          <input
            type="date"
            formControlName="end_date"
            name="end_date"
            id="end_date"
            class="form-control"
          />
        </td>
      </tr>
      <tr>
        <td><label for="award_date">Award</label></td>
        <td>
          <input
            type="date"
            formControlName="award_date"
            name="award_date"
            id="award_date"
            class="form-control"
          />
        </td>
      </tr>
      <tr>
        <td><label>Admins</label></td>
        <td>
          <ng-select
            formControlName="admins"
            name="admins"
            [multiple]="true"
            [addTag]="addNetid"
            addTagText="Add netid"
          >
          </ng-select>
        </td>
      </tr>
      <tr>
        <td><label>Extras</label></td>
        <td>
          <ng-select
            formControlName="extra"
            name="extra"
            [multiple]="true"
            [addTag]="addNetid"
            addTagText="Add netid"
          >
          </ng-select>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-outline-success"
      [disabled]="form.invalid || form.pristine"
    >
      Save
    </button>
  </div>
</form>
