import { Component } from '@angular/core';
import { EditionService } from '@app/edition.service';
import { Edition, User } from '../all.model';
import { UserService } from '../user.service';

@Component({
  templateUrl: './info.component.html',
})
export class InfoComponent {
  get user(): User {
    return this._user.user;
  }

  get edition(): Edition {
    return this._edition.edition;
  }

  constructor(private _user: UserService, private _edition: EditionService) {}
}
