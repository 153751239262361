import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Edition } from './all.model';

@Injectable()
export class EditionService implements Resolve<Edition> {
  edition: Edition;

  before: boolean;
  after: boolean;
  get active(): boolean {
    return !this.before && !this.after;
  }

  constructor(private http: HttpClient) {}

  async resolve(): Promise<Edition> {
    if (this.edition) {
      return this.edition;
    }

    this.edition = await this.http.get<Edition>('/api/edition').toPromise();

    const start = new Date(this.edition.start_date);
    this.before = new Date() < start;

    const end = new Date(this.edition.end_date);
    end.setUTCHours(23, 59, 59, 999);
    this.after = new Date() > end;
  }
}
