<form [formGroup]="form" (submit)="save()">
  <div class="d-flex">
    <div class="flex-grow-1 modal-header">
      <h4 class="modal-title w-100 text-center">
        {{ course ? 'Edit' : 'Create' }} course
      </h4>
    </div>
    <div
      class="modal-header pointer ml-2 d-flex justify-content-center"
      [style.width.px]="68"
      (click)="close()"
    >
      <h4 class="modal-title size-lg">
        &times;
      </h4>
    </div>
  </div>
  <table class="table">
    <tbody>
      <tr>
        <td><label for="name">Name</label></td>
        <td>
          <input
            type="text"
            formControlName="name"
            name="name"
            id="name"
            class="form-control"
          />
        </td>
      </tr>
      <tr>
        <td><label for="code">Code</label></td>
        <td>
          <input
            type="text"
            formControlName="code"
            name="code"
            id="code"
            class="form-control"
          />
        </td>
      </tr>
      <tr>
        <td><label>Studies</label></td>
        <td>
          <ng-select
            [items]="studies"
            formControlName="studies"
            name="studies"
            bindValue="id"
            bindLabel="name"
            [multiple]="true"
            [virtualScroll]="true"
          >
          </ng-select>
        </td>
      </tr>
      <tr>
        <td><label>Teachers</label></td>
        <td>
          <ng-select
            [items]="teachers"
            formControlName="teachers"
            name="teachers"
            bindValue="id"
            bindLabel="name"
            [multiple]="true"
            [virtualScroll]="true"
          >
          </ng-select>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-danger"
      (click)="delete()"
      *ngIf="course"
    >
      Delete
    </button>
    <button
      type="submit"
      class="btn btn-outline-success"
      [disabled]="form.invalid || form.pristine"
    >
      Save
    </button>
  </div>
</form>
