<div class="row">
  <div class="col-md-4">
    <div class="d-flex flex-column flex-md-column-reverse">
      <div>
        <h3>Filters</h3>
        <hr />
        <div>
          <h4>Bachelors</h4>
          <div class="row">
            <div class="col-6" *ngFor="let study of bachelors">
              <input
                type="checkbox"
                [id]="'B' + study.name"
                [name]="'B' + study.name"
                [(ngModel)]="study.selected"
                (ngModelChange)="fetch()"
              />
              <label [for]="'B' + study.name">{{ study.name }}</label>
              <br />
            </div>
          </div>

          <h4 class="mt-3">Masters</h4>
          <div class="row">
            <div class="col-6" *ngFor="let study of masters">
              <input
                type="checkbox"
                [id]="'M' + study.name"
                [name]="'M' + study.name"
                [(ngModel)]="study.selected"
                (ngModelChange)="fetch()"
              />
              <label [for]="'M' + study.name">{{ study.name }}</label>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-8">
    <h3>
      Results
      <span *ngIf="selected.length" class="download" (click)="download()">
        <svg
          height="25"
          viewBox="0 0 451.111 451.111"
          width="25"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m225.556 354.445 145-145-48.333-48.333-64.444 64.444v-225.556h-64.444v225.556l-64.444-64.444-48.333 48.333z"
          />
          <path d="m0 386.667h451.111v64.444h-451.111z" />
        </svg>
      </span>
    </h3>
    <hr class="blue" />

    <ng-container *ngIf="results">
      <p>
        <b>{{ results.votes - results.emptyVotes }}</b>
        people have voted on
        <b>{{ avg(results.teachers, results.votes - results.emptyVotes) }}</b>
        teachers on average.
        <b>{{ avg(results.comments, results.teachers, true) }}%</b>
        votes have comments. <b>{{ results.emptyVotes }}</b> dickhead{{
          results.emptyVotes == 1 ? '' : 's'
        }}
        haven't filled in anything.
      </p>

      <table class="table table-click mt-3">
        <tr>
          <th></th>
          <th>Name</th>
          <th>O</th>
          <th>1</th>
          <th>2</th>
        </tr>
        <tr
          *ngFor="let teacher of results.top; let index = index"
          (click)="details(teacher, modalTemplate)"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ teacher.name }}</td>
          <td>{{ teacher.votes[0] }}</td>
          <td>{{ teacher.votes[1] }}</td>
          <td>{{ teacher.votes[2] }}</td>
        </tr>
      </table>
    </ng-container>
  </div>
</div>

<ng-template #modalTemplate let-modal>
  <div class="d-flex">
    <div class="flex-grow-1 modal-header">
      <h4 class="modal-title w-100 text-center">{{ teacher.name }}</h4>
    </div>
    <div
      class="modal-header pointer ml-2 d-flex justify-content-center"
      [style.width.px]="68"
      (click)="modal.dismiss()"
    >
      <h4 class="modal-title size-lg">
        &times;
      </h4>
    </div>
  </div>
  <div class="modal-body">
    <p class="mb-2" *ngFor="let comment of teacher.comments">
      {{ comment.comment }} - <i class="text-black-50">{{ comment.netid }}</i>
    </p>

    <p *ngIf="!teacher.comments.length" class="text-center">
      <i>Geen commentaar gegeven...</i>
    </p>
  </div>
</ng-template>
