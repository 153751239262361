import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './notallowed.component.html',
})
export class NotallowedComponent {
  noeemcs = false;
  name = '';

  constructor(private route: ActivatedRoute) {
    this.noeemcs = route.snapshot.data.type === 'eemcs';
    this.name = route.snapshot.params.name;
  }
}
