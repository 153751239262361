<div class="min-vh-100 d-flex flex-column justify-content-center">
  <div class="container">
    <div class="white text-center my-5">
      <app-eemcs></app-eemcs>
      <h1>
        EEMCS Teacher of the Year
        <a *ngIf="user.user?.admin" routerLink="/admin">*</a>
      </h1>
      <div class="row pt-3">
        <div class="col-md-4 pt-3">
          <h3>Step 1</h3>
          <h4>Choose your teacher</h4>
          <p><i>Search for teachers directly</i></p>
        </div>
        <div class="col-md-4 pt-3">
          <h3>Step 2</h3>
          <h4>Explain yourself</h4>
          <p><i>It is optional, but highly appreciated</i></p>
        </div>
        <div class="col-md-4 pt-3">
          <h3>Step 3</h3>
          <h4>Repeat twice</h4>
          <p><i>Don't worry if you cannot think of three</i></p>
        </div>
      </div>
    </div>

    <div class="d-flex" *ngIf="data.loaded">
      <div class="col-4 tab" [class.active]="vote.no === 1" (click)="goNo(1)">
        Online choice
        <p
          class="text-primary text-overflow"
          [class.text-info]="!vote.teacher(1)"
        >
          {{ vote.teacher(1) || (vote.no === 1 ? 'Choosing...' : '-') }}
        </p>
      </div>
      <div class="col-4 tab" [class.active]="vote.no === 2" (click)="goNo(2)">
        Choice one
        <p
          class="text-primary text-overflow"
          [class.text-info]="!vote.teacher(2)"
        >
          {{ vote.teacher(2) || (vote.no === 2 ? 'Choosing...' : '-') }}
        </p>
      </div>
      <div
        class="col-4 tab"
        [class.active]="vote.no === 3"
        (click)="vote.vote(2).teacher && goNo(3)"
        [class.disabled]="!vote.vote(2).teacher"
      >
        Choice two
        <p
          class="text-primary text-overflow"
          [class.text-info]="!vote.teacher(3)"
        >
          {{ vote.teacher(3) || (vote.no === 3 ? 'Choosing...' : '-') }}
        </p>
      </div>
    </div>
  </div>

  <div class="main" *ngIf="data.loaded">
    <div class="container">
      <div class="row" [hidden]="!vote.selecting">
        <div class="col-xl-4 col-md-6">
          <div class="d-flex flex-column flex-md-column-reverse">
            <div>
              <h3>Filters</h3>
              <hr />
              <div>
                <h4>Bachelors</h4>
                <table class="w-100">
                  <tr *ngFor="let study of ['EE', 'AM', 'CS']">
                    <td>
                      <input
                        type="checkbox"
                        [id]="'B' + study"
                        [name]="'B' + study"
                        [checked]="data.selectedBachelor(study)"
                        (change)="
                          data.selectBachelor(study, $event.target.checked)
                        "
                      />
                      <label [for]="'B' + study">{{ study }}</label>
                    </td>
                    <td
                      *ngFor="
                        let year of data.bachelors[study].years | keyvalue
                      "
                    >
                      <ng-container *ngIf="data.selectedBachelor(study)">
                        <input
                          type="checkbox"
                          [id]="study + year.key"
                          [name]="study + year.key"
                          (change)="data.update.next()"
                          [(ngModel)]="year.value.checked"
                        />
                        <label [for]="study + year.key">
                          Year {{ year.value.year }}
                        </label>
                      </ng-container>
                    </td>
                  </tr>
                </table>

                <h4 class="mt-3">Masters</h4>
                <div class="row">
                  <div
                    class="col-6"
                    *ngFor="let study of data.masters | keyvalue"
                  >
                    <input
                      type="checkbox"
                      [id]="'M' + study.key"
                      [name]="'M' + study.key"
                      [(ngModel)]="study.value.checked"
                      (change)="data.update.next()"
                    />
                    <label [for]="'M' + study.key">{{ study.key }}</label>
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3>Search</h3>
              <hr />
              <input
                class="form-control"
                placeholder="Search teacher"
                (input)="data.update.next($event.target.value)"
              />
            </div>
          </div>
        </div>

        <div class="col-xl-8 col-md-6">
          <h3>Teachers</h3>
          <hr class="blue" />
          <div
            class="teacher d-flex justify-content-between align-items-center"
            *ngFor="let teacher of data.teachers"
          >
            <div class="name">{{ teacher.name }}</div>

            <div class="d-flex justify-content-end align-items-center">
              <i class="text-nowrap">{{ teacher.courses.length }} courses</i>
              <button
                type="button"
                class="btn btn-outline-info"
                (click)="voteTeacher(teacher)"
                [disabled]="vote.no > 1 && vote.selected.includes(teacher.id)"
              >
                Vote
              </button>
            </div>
          </div>
          <p class="text-center" *ngIf="!data.teachers.length">
            <i>Your search and filter selection have no result...</i>
          </p>
        </div>
      </div>

      <div class="row" *ngIf="!vote.selecting">
        <div class="col-md-4">
          <h3>{{ vote.vote(vote.no).teacher.name }}</h3>
          <hr />
          <h5>{{ vote.vote(vote.no).teacher.mail }}</h5>

          <h3>Courses</h3>
          <hr />
          <p *ngFor="let course of vote.vote(vote.no).teacher.courses">
            <i>{{ course.code }}</i> - {{ course.name }}
          </p>
        </div>

        <div class="col-md-8">
          <h3>Explain your choice</h3>
          <hr class="blue" />
          <textarea
            class="form-control"
            rows="6"
            [(ngModel)]="vote.vote().comments"
            placeholder="Optionally, provide a short explanation. All answers will be anonymised!"
          ></textarea>

          <button
            class="btn btn-outline-secondary mt-3"
            (click)="vote.selecting = true"
          >
            Other teacher
          </button>
          <div class="float-right mt-3">
            <button
              class="btn"
              [class.btn-outline-secondary]="vote.no !== 3"
              [class.btn-outline-info]="vote.no === 3"
              (click)="done()"
            >
              I'm done
            </button>
            <button
              class="btn btn-outline-info ml-3"
              (click)="goNo()"
              *ngIf="vote.no !== 3"
            >
              Next teacher
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
