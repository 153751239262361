import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { User } from './all.model';

@Injectable()
export class UserService implements Resolve<User> {
  user: User;

  constructor(private http: HttpClient) {}

  async resolve(): Promise<User> {
    if (this.user) {
      return this.user;
    }

    try {
      this.user = await this.http.get<User>('/api/user').toPromise();
    } catch (e) {
      console.error(e);
    }

    return this.user;
  }
}
