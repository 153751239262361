<div class="all">
  <div class="container text-center white">
    <h1>
      Hi, {{ user.name }}!
      <a *ngIf="user.admin" routerLink="/admin">*</a>
    </h1>

    <p>
      The contest is not yet ready open for voting. It will start
      {{ edition.start_date | date: 'EEEE, MMMM d' }}. You will be able to check
      out the results at the award show on
      {{ edition.award_date | date: 'EEEE, MMMM d' }}!
    </p>

    <div class="mt-3">
      <a class="btn btn-outline-primary text-white ml-3" href="api/saml/logout">
        Logout
      </a>
    </div>
  </div>
</div>
