<div class="d-flex">
  <div class="flex-grow-1 modal-header">
    <h4 class="modal-title w-100 text-center">
      Import
    </h4>
  </div>
  <div
    class="modal-header pointer ml-2 d-flex justify-content-center"
    [style.width.px]="68"
    (click)="close()"
  >
    <h4 class="modal-title size-lg">
      &times;
    </h4>
  </div>
</div>

<div class="modal-body">
  <div class="mb-3">
    <p class="mb-2">
      You can import an excel file to fill the database with teachers and
      courses from here.
      <span class="text-danger">It will empty the whole database first!</span>
      You create a row per combination of teacher, course and study. This means
      you have to create multiple rows per course to make the database
      understand there are multiple teachers teaching that course, for example.
      The example file shows so. All five columns have to be filled out, except
      for the study one, in which case the line will be ignored. The possible
      values of the study column are
      <ng-container *ngFor="let s of studies; let last = last"
        ><code>{{ s.name }}</code
        >{{ last ? '' : ', ' }}</ng-container
      >.
    </p>

    <p class="text-danger">
      Please make sure you understand the database will be emptied.
    </p>
  </div>

  <div class="alert alert-danger mb-3" *ngIf="error">
    There is something wrong with the excel file.
    <ng-container *ngFor="let e of error | keyvalue">
      {{
        e.key === 'study'
          ? 'Study "' + e.value + '" does not exist.'
          : 'A ' + e.key + ' (' + e.value + ') misses a field.'
      }}
    </ng-container>
  </div>

  <div class="d-flex justify-content-between">
    <button
      type="button"
      class="btn btn-outline-info"
      (click)="template()"
      title="Download example file"
    >
      Example
    </button>
    <button
      type="button"
      class="btn btn-outline-success"
      (click)="import()"
      [disabled]="!file"
      title="Import"
    >
      Import
    </button>
  </div>

  <div
    class="file pointer mt-3"
    [class.active]="dragging || file"
    (click)="fileInput.click()"
    (dragover)="$event.preventDefault(); $event.stopPropagation()"
    (dragenter)="dragging = true"
    (dragleave)="dragging = false"
    (drop)="$event.preventDefault(); setFile($event.dataTransfer.files)"
  >
    Drop hier je excel-bestand<br />
    <b *ngIf="file">{{ file.name }}</b>
    <input
      hidden
      type="file"
      #fileInput
      (change)="setFile($event.target.files)"
      accept=".xlsx"
    />
  </div>
</div>
