import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import FuzzySearch from 'fuzzy-search';
import { Subject } from 'rxjs';
import { scan } from 'rxjs/operators';
import { Course, Study, Teacher } from './all.model';
import { UserService } from './user.service';

@Injectable()
export class DataService {
  loaded = false;

  bachelors: any = {};
  masters: any = {};

  private _allTeachers: Teacher[] = [];
  teachers: Teacher[] = [];
  courses: Course[] = [];
  studies: Study[] = [];

  private fuzzy: FuzzySearch<Teacher>;

  update: Subject<string>;

  constructor(private http: HttpClient, private user: UserService) {
    this.update = new Subject<string>();

    ['EE', 'AM', 'CS'].forEach(study => {
      this.bachelors[study] = {
        years: {
          y1: null,
          y2: null,
          y3: null,
        },
      };
    });

    this.http
      .get<{ teachers: Teacher[]; courses: Course[]; studies: Study[] }>(
        '/api/data',
      )
      .subscribe(({ teachers, courses, studies }) => {
        this.courses = courses;

        this.studies = studies;
        this.studies.forEach(study => {
          study.checked = false;

          if (study.year) {
            this.bachelors[study.name].years['y' + study.year] = study;
          } else {
            this.masters[study.name] = study;
          }
        });

        for (let i = teachers.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [teachers[i], teachers[j]] = [teachers[j], teachers[i]];
        }
        this._allTeachers = teachers;
        this.teachers = teachers;

        this.fuzzy = new FuzzySearch(teachers, ['name']);

        this.studies
          .filter(
            x => (x.year ? 'BSc ' : 'MSc ') + x.name === this.user.user.study,
          )
          .forEach(x => (x.checked = true));

        this.update.next();
        this.loaded = true;
      });

    this.update
      .pipe(
        scan(
          (last: string, current: string) => (current == null ? last : current),
          '',
        ),
      )
      .subscribe((search: string) => {
        const ids = this.courses
          .filter(x =>
            this.studies
              .filter(y => y.checked)
              .reduce((s, y) => s.concat(y.courses), [])
              .includes(x.id),
          )
          .reduce((s, x) => s.concat(x.teachers), []);

        if (ids.length) {
          this.fuzzy.haystack = this._allTeachers.filter(x =>
            ids.includes(x.id),
          );
        } else {
          this.fuzzy.haystack = this._allTeachers;
        }

        this.teachers = this.fuzzy.search(search);
      });
  }

  selectBachelor(study: string, checked: boolean): void {
    this.bachelors[study].years.y1.checked = checked;
    this.bachelors[study].years.y2.checked = checked;
    this.bachelors[study].years.y3.checked = checked;

    this.update.next();
  }

  selectedBachelor(study: string): boolean {
    const years = this.bachelors[study].years;

    return (
      this.studies.length && [years.y1, years.y2, years.y3].some(x => x.checked)
    );
  }
}
