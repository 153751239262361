import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class NonAuthGuard implements CanActivate {
  constructor(private user: UserService, private router: Router) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const user = await this.user.resolve();

    if (user) {
      return this.router.parseUrl('');
    }

    return true;
  }
}
