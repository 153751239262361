import { Component } from '@angular/core';
import { Edition } from '@app/all.model';
import { EditionService } from '@app/edition.service';

@Component({
  templateUrl: './login.component.html',
})
export class LoginComponent {
  get edition(): Edition {
    return this._edition.edition;
  }

  constructor(private _edition: EditionService) {}
}
