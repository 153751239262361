import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Course, Study, Teacher } from '../all.model';

@Component({
  templateUrl: './course-modal.component.html',
})
export class CourseModalComponent implements OnInit {
  form: FormGroup;

  course: Course;

  teachers: Teacher[];
  studies: Study[];

  constructor(
    private http: HttpClient,
    private modal: NgbActiveModal,
    private formBuilder: FormBuilder,
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      id: [],
      name: ['', [Validators.required]],
      code: ['', [Validators.required]],
      studies: [[], [Validators.required]],
      teachers: [[]],
    });

    this.form.patchValue(this.course);
  }

  async save(): Promise<void> {
    try {
      const course = await this.http
        .post('/api/admin/course', this.form.value)
        .toPromise();

      this.modal.close(course);
    } catch (e) {
      console.error(e);
    }
  }

  async delete(): Promise<void> {
    try {
      await this.http.delete(`/api/admin/course/${this.course.id}`).toPromise();

      this.modal.close();
    } catch (e) {
      console.error(e);
    }
  }

  close(): void {
    this.modal.dismiss();
  }
}
