import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Teacher } from '../all.model';
import { DataService } from '../data.service';
import { UserService } from '../user.service';
import { VoteService } from '../vote.service';

@Component({
  templateUrl: './vote.component.html',
})
export class VoteComponent {
  constructor(
    private router: Router,
    public user: UserService,
    public data: DataService,
    public vote: VoteService,
  ) {}

  voteTeacher(teacher: Teacher): void {
    this.vote.saveVote(teacher, '');

    this.vote.selecting = false;
  }

  goNo(no: number): void {
    if (this.vote.vote().teacher) {
      this.vote.saveVote();
    }

    this.vote.no = no ? no : this.vote.no + 1;

    this.vote.selecting = !this.vote.vote().teacher;
  }

  done(): void {
    this.vote.saveVote();

    this.router.navigate(['done']);
  }
}
