import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import locale from '@angular/common/locales/en';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdminComponent } from './admin/admin.component';
import { AppComponent } from './app.component';
import { AuthHttpInterceptor } from './auth-http.interceptor';
import { AuthGuard } from './auth.guard';
import { CourseModalComponent } from './course-modal/course-modal.component';
import { CoursesComponent } from './courses/courses.component';
import { DataService } from './data.service';
import { DoneComponent } from './done/done.component';
import { EditionModalComponent } from './edition-modal/edition-modal.component';
import { EditionService } from './edition.service';
import { EemcsComponent } from './eemcs/eemcs.component';
import { ImportModalComponent } from './import-modal/import-modal.component';
import { InfoComponent } from './info/info.component';
import { LoginComponent } from './login/login.component';
import { NonAuthGuard } from './non-auth.guard';
import { NotallowedComponent } from './notallowed/notallowed.component';
import { ResultsComponent } from './results/results.component';
import { StageGuard } from './stage.guard';
import { TeacherModalComponent } from './teacher-modal/teacher-modal.component';
import { TeachersComponent } from './teachers/teachers.component';
import { UserService } from './user.service';
import { VoteService } from './vote.service';
import { VoteComponent } from './vote/vote.component';

registerLocaleData(locale, 'en');

const appRoutes: Routes = [
  {
    path: '',
    resolve: { edition: EditionService },
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard],
      },
      {
        path: 'nostud/:name',
        component: NotallowedComponent,
        data: {
          type: 'stud',
        },
      },
      {
        path: 'noeemcs/:name',
        component: NotallowedComponent,
        data: {
          type: 'eemcs',
        },
      },
      {
        path: 'info',
        component: InfoComponent,
        canActivate: [AuthGuard, StageGuard],
      },
      {
        path: 'vote',
        component: VoteComponent,
        canActivate: [AuthGuard, StageGuard],
      },
      {
        path: 'done',
        component: DoneComponent,
        canActivate: [AuthGuard, StageGuard],
      },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'teachers',
            component: TeachersComponent,
          },
          {
            path: 'courses',
            component: CoursesComponent,
          },
          {
            path: 'results',
            component: ResultsComponent,
          },
          {
            path: '**',
            redirectTo: 'results',
          },
        ],
      },
      {
        path: '**',
        redirectTo: 'vote',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  declarations: [
    AppComponent,
    EemcsComponent,
    LoginComponent,
    NotallowedComponent,
    InfoComponent,
    VoteComponent,
    DoneComponent,
    AdminComponent,
    TeachersComponent,
    TeacherModalComponent,
    CoursesComponent,
    CourseModalComponent,
    ImportModalComponent,
    EditionModalComponent,
    ResultsComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en',
    },
    AuthGuard,
    NonAuthGuard,
    StageGuard,
    UserService,
    EditionService,
    DataService,
    VoteService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
